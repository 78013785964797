import '../styles/styles.scss';
import '../styles/fiori.css';
import '../../node_modules/select2/dist/css/select2.min.css';
import '../../node_modules/select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.rtl.min.css';

import '../styles/tabs.css';
import '../styles/animations.css';
import '../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import '../../node_modules/tabulator-tables/dist/css/tabulator_bootstrap5.min.css';
import '../../node_modules/daterangepicker/daterangepicker.css';
import '../../node_modules/bootstrap-fileinput/css/fileinput.min.css';
import '../../node_modules/bootstrap-fileinput/themes/explorer/theme.min.css';
import '../styles/main.css';

/* global Stimulus */
import '@hotwired/turbo';
import 'bootstrap';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import * as Sentry from '@sentry/browser';

/* global sentry configuration */
Sentry.init({
  release: 'frontend-@' + process.env.GIT_TAG,
  environment: process.env.SENTRY_ENV,
  dsn: process.env.SENTRY_DSN,
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    'localhost',
    'http://127.0.0.1:8000',
    /^https:\/\/.*\.ibuqa\.io/,
  ],

  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

window.Stimulus = Application.start();
const context = require.context('../controllers', true, /\.js$/);
Stimulus.load(definitionsFromContext(context));
